var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "mr-3 dark-grey-buttons",
          attrs: {
            id: "tooltip-button-feedback",
            "data-beamer-click": "false"
          },
          on: { click: _vm.goToHelpCenter }
        },
        [
          _c("b-icon", {
            staticClass: "top--1",
            attrs: {
              icon: "question-circle",
              "aria-hidden": "true",
              "font-scale": "1.1",
              scale: "1.1"
            }
          })
        ],
        1
      ),
      _c(
        "b-tooltip",
        { attrs: { target: "tooltip-button-feedback", delay: "400" } },
        [_vm._v(" " + _vm._s(_vm.$t("tooltips.helpCenter")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }