<template>
  <div>
    <b-button id="tooltip-button-feedback" class="mr-3 dark-grey-buttons"
              data-beamer-click="false"
              @click="goToHelpCenter">
      <b-icon
        icon="question-circle"
        aria-hidden="true"
        class="top--1"
        font-scale="1.1"
        scale="1.1"
      ></b-icon>
    </b-button>
    <b-tooltip target="tooltip-button-feedback" delay=400>
      {{ $t('tooltips.helpCenter') }}</b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'HeaderHelpCenterButton',
  data() {
    return {
      lang: 'en',
    };
  },
  created() {
    if (this.$i18n.locale === 'de-DE') {
      this.lang = 'de';
    } else {
      this.lang = 'en';
    }
  },
  methods: {
    goToHelpCenter() {
      const url = (this.lang === 'en')
        ? 'https://helpdesk.webmag.io/en/'
        : 'https://helpdesk.webmag.io/de/';
      // open link in new tab
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>

</style>
